import React, { useState } from 'react'
import './ActionCard.css'

const ActionCard = ({ props }) => {

    return (
        <div className="card" id="actionCard">
            <div className="cardTitleActions">
                <h2>ACTIONS</h2>
            </div>
            <div className="cardContent">
                <span>
                    <div className='button-div'>
                        {!props.startEventState ? (
                        <button
                            className="eventStartBtn"
                            onClick={props.handleFirstModalOpen}
                        >
                            Event Start
                        </button>) :
                        (
                        <button
                            className="eventEndBtn end"
                            onClick={props.handleSecondModalOpen}
                        >
                            Event End
                        </button>
                        )}
                    </div>

                    <h6>Allow/disable event streaming</h6>
                </span>

                <span>
                    <div className='button-div'>
                        {!props.startMatchState ? (
                        <button
                            className="matchStartBtn"
                            onClick={props.handleThirdModalOpen}
                            disabled={!props.startEventState}
                        >
                            Match Start
                        </button>) : (
                        <button
                            className="matchEndBtn end"
                            onClick={props.handleFourthModalOpen}
                        >
                            Match End
                        </button>
                        )}
                    </div>
                    <h6>Define start/end of the match</h6>
                </span>
                <span>
                    <div className='button-div'>
                        {!props.startIntervalState ? (
                        <button
                            className="intervalStartBtn"
                            onClick={props.handleFifthModalOpen}
                            disabled={props.startEventState === false || props.startMatchState === false ? true : false}
                        >
                            Interval Start
                        </button>) :
                        <button
                            className="intervalEndBtn end"
                            onClick={props.handleSixthModalOpen}
                        >
                            Interval End
                        </button>}
                    </div>
                    <h6>Define start/end of a match interval (one or more)</h6>
                </span>
                <span>
                    <div className='button-div'>
                        {!props.startAnalysisState ? (
                        <button
                            className="analysisStartBtn"
                            onClick={props.handleSeventhModalOpen}
                            disabled={!props.startEventState}
                        >
                            Analysis Start
                        </button>) :
                        (<button
                            className="analysisEndBtn end"
                            onClick={props.handleEighthModalOpen}
                        >
                            Analysis End
                        </button>
                        )}
                    </div>
                    <h6>Define start/end of Reddit/Youtube sentiment analysis</h6>
                </span>
            </div>
        </div>
    )
}

export default ActionCard;