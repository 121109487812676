import './createEventV2.css';
import './createEvent.css';
import React, { useEffect, useState } from 'react';
import ReactTagInput from '@pathofdev/react-tag-input';
import Map from '../../components/Map/Map';
import CircularProgress from '@mui/material/CircularProgress';
import 'rodal/lib/rodal.css';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { ToastContainer, toast } from 'react-toastify';
const axios = require('axios');

const CreateEventV2 = () => {
  let [countries, setCountries] = useState([]);
  let [leaguesByCountry, setLeaguesByCountry] = useState([]);
  let [selectedCountry, setSelectedCountry] = useState('Portugal');
  let [selectedLeague, setSelectedLeague] = useState('94');
  let [selectedEvent, setSelectedEvent] = useState(null);
  let [selectedSport, setSelectedSport] = useState('Football');
  let [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  let [filteredFixtures, setFilteredFixtures] = useState([]);
  let [isModalOpen, setIsModalOpen] = useState(false);
  const [redditTags, setRedditTags] = useState([]);
  const [twitterTags, setTwitterTags] = useState([]);
  const [eventAddress, setEventAddress] = useState('');
  const [eventLat, setEventLat] = useState(null);
  const [eventLng, setEventLng] = useState(null);
  const [eventRadius, setEventRadius] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createEventData, setCreateEventData] = useState({});
  const [isAllSetToSubmit, setIsAllSetToSubmit] = useState(false);

  const getState = (value) => {
    setEventAddress(value.address);
    setEventLat(value.lat);
    setEventLng(value.lng);
    setEventRadius(value.radius);
  };

  const handleFormSubmit = () => {
    const newEvent = {
      eventSportType: selectedSport,
      eventStartDate: startDate,
      eventEndDate: endDate,
      fixtureId: selectedEvent.fixture.id,
      isEventStarted: false,
      isMatchStarted: false,
      isIntervalStarted: false,
      isAnalysisStarted: false,
      redditTags: redditTags,
      twitterTags: twitterTags,
      address: eventAddress,
      lat: eventLat,
      lng: eventLng,
      radius: eventRadius,
      hometeamname: selectedEvent.teams.home.name,
      hometeamlogo: selectedEvent.teams.home.logo,
      awayteamname: selectedEvent.teams.away.name,
      awayteamlogo: selectedEvent.teams.away.logo,
      country: selectedEvent.league.country,
      leaguename: selectedEvent.league.name,
    };
    setIsModalOpen(false);
    createEvent(newEvent);
  };

  async function createEvent(newEvent) {
    try {
      let redditChannelsFormatted = '';
      for (let channel of newEvent.redditTags) {
        redditChannelsFormatted += channel + ',';
      }

      let twitterChannelsFormatted = '';
      for (let channel of newEvent.twitterTags) {
        twitterChannelsFormatted += channel + ',';
      }

      redditChannelsFormatted = redditChannelsFormatted.substring(0, redditChannelsFormatted.length - 1);
      twitterChannelsFormatted = twitterChannelsFormatted.substring(0, twitterChannelsFormatted.length - 1);

      await axios.post(`${window.API_URL}/api/admin/events`, {
        fixtureId: newEvent.fixtureId,
        hometeamname: newEvent.hometeamname,
        hometeamlogo: newEvent.hometeamlogo,
        awayteamname: newEvent.awayteamname,
        awayteamlogo: newEvent.awayteamlogo,
        country: newEvent.country,
        leaguename: newEvent.leaguename,
        sportType: newEvent.eventSportType,
        startDate: newEvent.eventStartDate,
        endDate: newEvent.eventEndDate,
        redditTagId: redditChannelsFormatted,
        twitterTagId: twitterChannelsFormatted,
        isEventStarted: newEvent.isEventStarted,
        isMatchStarted: newEvent.isMatchStarted,
        isIntervalStarted: newEvent.isMatchStarted,
        isAnalysisStarted: newEvent.isAnalysisStarted,
        address: newEvent.address,
        latitude: newEvent.lat,
        longitude: newEvent.lng,
        radius: newEvent.radius,
      });
      toast.success('Event Created with success!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error('Error creating an Event!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getFootballCountries() {
    const storedCountries = localStorage.getItem('Countries');
    let countriesArray = [];
    if (storedCountries !== null) {
      countriesArray = JSON.parse(storedCountries);
    }

    if (countriesArray.length === 0) {
      const { data } = await axios.get(`${window.API_URL}/api/admin/football/countries`);
      localStorage.setItem('Countries', JSON.stringify(data));
      setCountries(data);
    } else {
      setCountries(countriesArray);
    }
  }

  async function getLeaguesByCountry(country) {
    const { data } = await axios.get(`${window.API_URL}/api/admin/football/leagues/${country}`);
    setLeaguesByCountry(data);
    setSelectedLeague(data[0]?.league.id);
  }

  async function getFixturesByLeagueSeasonAndDate(leagueId, season, date) {
    const { data } = await axios.get(`${window.API_URL}/api/admin/football/fixtures/${season}/${leagueId}/${date}`);
    setFilteredFixtures(data);
  }

  function countryHandler(e) {
    setSelectedCountry(e.target.value);
  }

  function leagueHandler(e) {
    setSelectedLeague(e.target.value);
  }

  function dateHandler(e) {
    setDate(e.target.value);
  }

  function startDateHandler(e) {
    setStartDate(e.target.value);
  }

  function endDateHandler(e) {
    setEndDate(e.target.value);
  }

  function selectCurrentEvent(event) {
    setSelectedEvent(event);
  }

  function eventTimeHandler(time) {
    let result = '';
    let timeFormatted = new Date(time);
    let hoursResult = timeFormatted.getHours().toString();
    let minutesResult = timeFormatted.getMinutes().toString();
    if (timeFormatted.getMinutes() < 10) {
      result = hoursResult.concat(':0', minutesResult);
    } else {
      result = hoursResult.concat(':', minutesResult);
    }
    return result;
  }

  function showModal() {
    setIsModalOpen(true);
  }

  function hideModal() {
    setIsModalOpen(false);
  }

  function sportHandler(e) {
    setSelectedSport(e.target.value);
  }

  useEffect(
    function formHandler() {
      if (
        selectedEvent !== null &&
        eventAddress !== null &&
        eventLat !== null &&
        eventLng !== null &&
        eventRadius !== null &&
        (startDate != null) & (endDate != null)
      ) {
        setIsAllSetToSubmit(true);
      } else {
        setIsAllSetToSubmit(false);
      }
    },
    [selectedEvent, twitterTags, redditTags, eventAddress, eventLat, eventLng, eventRadius, startDate, endDate]
  );

  useEffect(async () => {
    setLoading(true);
    await getFootballCountries();
    let country = selectedCountry;
    await getLeaguesByCountry(country);
    setLoading(false);
  }, [selectedCountry]);

  useEffect(() => {
    if (!loading) {
      document.querySelector('#date').value = date;
    }
  }, [loading]);

  useEffect(async () => {
    setLoading(true);
    await getFixturesByLeagueSeasonAndDate(selectedLeague, '2023', date);
    setLoading(false);
  }, [selectedLeague, date]);

  useEffect(() => {
    if (selectedEvent) {
      if (document.querySelectorAll('.event').length > 0) {
        document.querySelectorAll('.event')?.forEach((event) => {
          if (event.dataset.id == selectedEvent.fixture.id) {
            event.classList.add('active');
          } else {
            event.classList.remove('active');
          }
        });
      }
    }
  }, [selectedEvent]);

  return !loading ? (
    <div className='createEventMainDiv'>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='main'>
        <div className='left-column'></div>
        <div className='middle-column'>
          <div className='events-container'>
            <div className='filters-bar'>
              <form>
                <div className='filter'>
                  <label htmlFor='sport'>Sport</label>
                  <select name='sport' id='sport' value={selectedSport} onChange={sportHandler}>
                    <option value='football'>Football</option>
                  </select>
                </div>
                <div className='filter'>
                  <label htmlFor='competition'>Competition</label>
                  <select name='competition' id='competition' onChange={leagueHandler} defaultValue={selectedLeague}>
                    {leaguesByCountry.map((league, index) => {
                      return (
                        <option key={index} value={league.league.id}>
                          {league.league.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='filter'>
                  <label htmlFor='country'>Country</label>
                  <select name='country' id='country' value={selectedCountry} onChange={countryHandler}>
                    {countries.map((country, index) => {
                      return (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='filter'>
                  <label htmlFor='date'>Date</label>
                  <input type='date' id='date' name='date' onChange={dateHandler}></input>
                </div>
              </form>
            </div>
            <div className='events-display'>
              {filteredFixtures.length > 0 ? (
                filteredFixtures.map((event, index) => {
                  return (
                    <div className='event' data-id={event.fixture.id} key={index}>
                      <div className='details'>
                        <img
                          style={{ objectFit: 'contain' }}
                          alt='League Logo'
                          src={event.league.flag ? event.league.flag : event.league.logo}
                        ></img>
                        <div className='info'>
                          <p className='country'>{event.league.country}</p>
                          <p className='competition'>{event.league.name}</p>
                        </div>
                      </div>
                      <div className='teams'>
                        <div className='home-team-div'>
                          <img alt='Home Team Logo' src={event.teams.home.logo}></img>
                          <p className='home-team'>{event.teams.home.name}</p>
                        </div>
                        <div className='away-team-div'>
                          <img alt='Away Team Logo' src={event.teams.away.logo}></img>
                          <p className='away-team'>{event.teams.away.name}</p>
                        </div>
                      </div>
                      <div className='start-time'>
                        <p>{eventTimeHandler(event.fixture.date)}</p>
                      </div>
                      <div className='button-div'>
                        <div className='button' onClick={() => selectCurrentEvent(event)}>
                          <p>+</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='noEventsDiv'>
                  <h2>There are no events available...</h2>
                </div>
              )}
            </div>
          </div>
          <div className='date-container'>
            <div className='start-date-container'>
              <label htmlFor='start-date'>Start Date</label>
              <input type='datetime-local' id='start-date' name='date' onChange={startDateHandler}></input>
            </div>
            <div className='end-date-container'>
              <label htmlFor='end-date'>End Date</label>
              <input type='datetime-local' id='end-date' name='date' onChange={endDateHandler}></input>
            </div>
          </div>
          {/*<div className='social-media-container'>
            <div className='reddit-container'>
              <label id='eventRedditChannelLabel' htmlFor='redditChannel'>
                Reddit Channels
              </label>
              <span>*</span>
              <ReactTagInput
                id='redditChannel'
                removeOnBackspace='true'
                tags={redditTags}
                maxTags={5}
                placeholder='Type a channel and press enter'
                editable={true}
                readOnly={false}
                required
                onChange={(newTags) => setRedditTags(newTags)}
              />
              <h6>
                Reddit Channels use for social media analysis concerning this event. You must provide one or more URLs
                of Reddit threads.
              </h6>
            </div>
            <div className='youtube-container'>
              <label id='eventTwitterChannelLabel' htmlFor='twitterChannel'>
                Youtube Channels
              </label>
              <span>*</span>
              <ReactTagInput
                id='twitterChannel'
                removeOnBackspace='true'
                tags={twitterTags}
                maxTags={5}
                placeholder='Type a channel and press enter'
                editable={true}
                readOnly={false}
                required
                onChange={(newTags) => setTwitterTags(newTags)}
              />
              <h6>
                Youtube channels used for social media analysis concerning this event. You must provide one or more URLs
                of Youtube.
              </h6>
            </div>
          </div>*/}
          <div className='map-container'>
            <label id='eventMapLabel' component='legend' htmlFor='map'>
              Event Location
            </label>
            {/* <Map liftingStateUpHandler={getState} /> */}
            <Map liftingStateUpHandler={getState} />
          </div>
          <div className='submit-button-container'>
            {isAllSetToSubmit ? (
              <button type='text' className='submit-button' onClick={showModal}>
                + Create event
              </button>
            ) : (
              <button
                type='text'
                disabled
                className='submit-button'
                style={{ cursor: 'not-allowed', backgroundColor: 'grey' }}
                onClick={showModal}
              >
                + Create event
              </button>
            )}
          </div>
        </div>
        <div className='right-column'></div>
        <Rodal visible={isModalOpen} className='modal' onClose={hideModal}>
          <div className='header'>
            <p>Create Event</p>
          </div>
          <div className='content'>
            <p>Are you sure you want to create an event?</p>
          </div>
          <div className='footer'>
            <button type='text' className='cancel' onClick={hideModal}>
              Cancel
            </button>
            <button type='text' className='create' onClick={handleFormSubmit}>
              Create
            </button>
          </div>
        </Rodal>
      </div>
    </div>
  ) : (
    <CircularProgress className='loading' color='success' size='lg' />
  );
};

export default CreateEventV2;
