import React, { useState, useRef, useEffect } from 'react';
import './Map.css';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
  useLoadScript,
  StreetViewService,
  InfoWindow,
} from '@react-google-maps/api';
import { Card, CardContent, Collapse, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import Geocode from 'react-geocode';
import RoomIcon from '@mui/icons-material/Room';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const libraries = ['places'];

export const Map = ({ liftingStateUpHandler }) => {
  const [expanded, setExpanded] = useState(true);
  const [secondExpanded, setSecondExpanded] = useState(false);
  const [zoom, setZoom] = useState(12);
  const [cardWidth, setCardWidth] = useState('30%');
  const [hidden, setHidden] = useState(0);
  const [circleVisibility, setCircleVisibility] = useState(false);
  const [markerVisibility, setMarkerVisibility] = useState(false);
  const [address, setAddress] = useState();
  const circleRef = useRef();
  const mapRef = useRef();
  const [radius, setRadius] = useState(2000);
  const [center, setCenter] = useState({
    lat: 41.1579438,
    lng: -8.6291053,
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAA1H4-rS8hDmUvu7DHHz5_gphwlziGdKM',
    libraries,
  });

  Geocode.setApiKey('AIzaSyAA1H4-rS8hDmUvu7DHHz5_gphwlziGdKM');

  const handleExpandClick = (event) => {
    event.preventDefault();

    setExpanded(!expanded);

    handleCardWidth();

    if (hidden == 1) {
      setSecondExpanded(!secondExpanded);
    }
  };

  const handleCardWidth = () => {
    if (!expanded) {
      setCardWidth('30%');
    }
    if (expanded) {
      setCardWidth('20%');
    }
  };

  const PlacesAutoComplete = () => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    // if (value == "" && address != "") {
    //   setValue(address);
    // }

    if (value == '' && address != '') {
      setValue(address);
    }

    const ref = useOnclickOutside(() => {
      clearSuggestions();
    });

    const handleInput = (e) => {
      setValue(e.target.value);
    };

    const handleSelect = (event, description) => {
      event.preventDefault();
      setValue(description, false);
      setCircleVisibility(true);
      setMarkerVisibility(true);
      setSecondExpanded(true);
      setHidden(1);

      getGeocode({ address: description }).then((results) => {
        // console.log(
        //   results[0].geometry.location.lat(),
        //   results[0].geometry.location.lng()
        // );
        handleChangeCircleCenter(results[0].geometry.location.lat(), results[0].geometry.location.lng());
      });
    };

    const renderSuggestions = () => {
      // console.log(data);
      return (
        <div>
          {data.map((item) => (
            <li
              key={item.place_id}
              onClick={(e) =>
                handleSelect(e, item.structured_formatting.main_text + ' ' + item.structured_formatting.secondary_text)
              }
              className='suggestionItem'
            >
              <RoomIcon id='markerIcon' />
              <strong>{item.structured_formatting.main_text}</strong>
              {', '}
              <small>{item.structured_formatting.secondary_text}</small>
            </li>
          ))}
        </div>
      );
    };

    return (
      <div ref={ref}>
        <input value={value} onChange={handleInput} placeholder='Insert a location' id='txtLocation' required />
        {status === 'OK' && <ul className='suggestionList'>{renderSuggestions()}</ul>}
      </div>
    );
  };

  const handleChangeCircleCenter = (lat, lng) => {
    if (lat && lng) {
      handleAddress(lat, lng);
      setCenter({
        lat: parseFloat(lat.toFixed(4)),
        lng: parseFloat(lng.toFixed(4)),
      });
    } else {
      if (circleVisibility) {
        if (circleRef.current.registeredEvents[0]?.Cb) {
          handleAddress(
            circleRef.current.registeredEvents[0].Cb?.center.lat(),
            circleRef.current.registeredEvents[0].Cb?.center.lng()
          );
          setCenter({
            lat: parseFloat(circleRef.current.registeredEvents[0].Cb?.center.lat().toFixed(4)),
            lng: parseFloat(circleRef.current.registeredEvents[0].Cb?.center.lng().toFixed(4)),
          });
        }
      }
    }
  };

  const handleAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        // console.log(response.results[0].formatted_address);
        setAddress(response.results[0].formatted_address);
        sendInfo(parseFloat(lat.toFixed(4)), parseFloat(lng.toFixed(4)), radius, response.results[0].formatted_address);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleChangeRadius = () => {
    if (circleVisibility) {
      if (circleRef.current.registeredEvents[1].Cb) {
        // console.log(
        //   parseFloat(
        //     circleRef.current.registeredEvents[1].Cb?.radius.toFixed(1)
        //   )
        // );
        setRadius(parseFloat(circleRef.current.registeredEvents[1].Cb?.radius.toFixed(1)));
        sendInfo(
          center.lat,
          center.lng,
          parseFloat(circleRef.current.registeredEvents[1].Cb?.radius.toFixed(1)),
          address
        );
      }
    }
  };

  const handleZoom = () => {
    if (mapRef.current.state.map.zoom < 17) {
      setZoom(mapRef.current.state.map.zoom + 1);
    }
  };

  const sendInfo = (lat, lng, radius, address) => {
    liftingStateUpHandler({
      lat: lat,
      lng: lng,
      radius: radius,
      address: address,
    });
  };

  return isLoaded ? (
    <div>
      <GoogleMap
        ref={mapRef}
        className='map'
        mapContainerStyle={{ height: '458px', width: '100%' }}
        center={center}
        zoom={zoom}
      >
        <Marker
          className='marker'
          key='1'
          position={{
            lat: parseFloat(center.lat),
            lng: parseFloat(center.lng),
          }}
          visible={markerVisibility}
          onClick={handleZoom}
        >
          {markerVisibility ? (
            <InfoWindow
              position={{
                lat: parseFloat(center.lat),
                lng: parseFloat(center.lng),
              }}
            >
              <div>{address}</div>
            </InfoWindow>
          ) : (
            <></>
          )}
        </Marker>
        <Circle
          ref={circleRef}
          options={{
            strokeColor: '#FF0000',
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: '#C64D45',
            fillOpacity: 0.5,
            radius: radius,
            center: {
              lat: parseFloat(center.lat),
              lng: parseFloat(center.lng),
            },
            clickable: false,
            draggable: true,
            editable: true,
          }}
          onCenterChanged={handleChangeCircleCenter}
          onRadiusChanged={handleChangeRadius}
          visible={circleVisibility}
          // onDragEnd={(event) => console.log(event.latLng.toString())}
        ></Circle>
        <Card className='mapCard' sx={{ width: cardWidth }}>
          <CardContent className='cardContent'>
            <Typography sx={{ color: 'white' }}>Location Search</Typography>
            <ExpandMore
              expand={expanded}
              onClick={(e) => handleExpandClick(e)}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon id='expandIcon' />
            </ExpandMore>
          </CardContent>
          <div id='showInfo'>
            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <Typography>Location</Typography>
              <PlacesAutoComplete />
            </Collapse>
          </div>
          <Collapse in={secondExpanded} timeout='auto' unmountOnExit>
            <CardContent className='hiddenInfo'>
              <Typography className='firstTypography'>Coordinates</Typography>
              <input id='txtCoords' type='text' disabled value={`${center.lat}` + `, ` + `${center.lng}`} />
              <Typography id='secondTypography'>Range (in meters)</Typography>
              <input type='text' id='txtRange' disabled value={radius} />
            </CardContent>
          </Collapse>
        </Card>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default Map;
