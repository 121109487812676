import React from 'react';
import './InfoCard.css'
import { Grid } from '@mui/material'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const InfoCard = ({ props }) => {
  useEffect(() => {
    console.log(props)
  }, [])
  const navigate = useNavigate();
  return (
    <div className="card" id="infoCard">
      <div className='header'>
        <h3>EVENT INFORMATION</h3>
        <button className="infoBtn" onClick={(e) => {
          e.preventDefault();
          navigate("/", { state: props.eventData.eventInfo.id });
        }}>+ More Info</button>
      </div>
      <div className='content'>
        <div className='top-row'>
          <div className='event-id'>
            <h4>ID: </h4>
            <p>{props.eventData.eventInfo.id}</p>
          </div>
          <div className='event-sport-type'>
            <h4>Sport Type: </h4>
            <p>{props.eventData.eventInfo.sporttype}</p>
          </div>
          <div className='event-start-date'>
            <h4>Start Date: </h4>
            <p>{props.eventData.eventInfo.startdate.split("T")[0] + " " + props.eventData.eventInfo.startdate.split("T")[1].split("Z")[0].split(".")[0]}</p>
          </div>
          <div className='event-end-date'>
            <h4>End Date: </h4>
            <p>{props.eventData.eventInfo.enddate.split("T")[0] + " " + props.eventData.eventInfo.enddate.split("T")[1].split("Z")[0].split(".")[0]}</p>
          </div>
        </div>
        <div className='bottom-row'>
          <div className='event-teams'>
            <h4>Teams: </h4>
            <p>{props.eventData.eventInfo.hometeamname} <img src={props.eventData.eventInfo.hometeamlogo} id='home-team-logo' alt='home team logo'></img> VS <img src={props.eventData.eventInfo.awayteamlogo} id='away-team-logo' alt='away team logo'></img> {props.eventData.eventInfo.awayteamname}</p>
          </div>
        </div>
      </div>
      {/* <Grid id="infoGrid" container spacing={2}>
              <Grid item lg={12}>
                <button className="infoBtn" onClick={(e)=> {
                  e.preventDefault();
                  navigate("/", { state: props.eventData.eventInfo.id });
                }}>+ More Info</button>
                <h2 className="infoTitle">Event Information</h2>

                <Grid id="secondInfoGrid" container spacing={2}>
                  <Grid item lg={6}>
                    <div className="cardContent">
                      <h3>ID</h3>
                      <h4>{props.eventData.eventInfo.id}</h4>
                      <h3>Teams</h3>
                      <h4>{props.eventData.eventInfo.hometeamname} vs {props.eventData.eventInfo.awayteamname}</h4>
                    </div>
                  </Grid>
                  <Grid
                    justifyContent="flex-end"
                    className="secondItem"
                    item
                    lg={6}
                  >
                    <h3>Sport Type</h3>
                    <h4>{props.eventData.eventInfo.sporttype} Match</h4>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
    </div>
  )
}

export default InfoCard;