import './App.css';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoPlayOff from './assets/Logo-PlayOff-Verde-22 1.svg';
import firstIcon from './assets/dashboard 1.svg';
import secondIcon from './assets/page 1.svg';
import thirdIcon from './assets/list-symbol-of-three-items-with-dots 1.svg';
import { BrowserRouter as Router, NavLink, Routes, Route } from 'react-router-dom';
import CreateEventV2 from './pages/createEvent/createEventV2';
import EventManagement from './pages/eventManagement/eventManagement';
import EventOverview from './pages/eventOverview/eventOverview';
import EditEvent from './pages/editEvent/editEvent';
import Demographics from './pages/demographics/demographics';
import Sentiments from './pages/sentiments/sentiments';
import Topics from './pages/topics/topics';

const drawerWidth = 240;

function App(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userRole, setUserRole] = useState('teamManager');

  const handleDrawerToggle = (event) => {
    event.preventDefault();
    setMobileOpen(!mobileOpen);
  };

  const [titleNumber, setTitleNumber] = useState(1);

  const first = () => {
    setTitleNumber(1);
  };

  const second = () => {
    setTitleNumber(2);
  };

  const third = () => {
    setTitleNumber(3);
  };

  const fourth = () => {
    setTitleNumber(4);
  };

  const drawer = (
    <div>
      <Toolbar />
      <List>
        <NavLink
          to='/eventOverview'
          aria-disabled
          onClick={first}
          className='event-overview-link'
          activeclassname='selected'
        >
          <ListItem button disableRipple>
            <ListItemIcon>
              <img src={firstIcon} alt='firstIcon' />
            </ListItemIcon>
            <ListItemText primary='Event Overview' />
          </ListItem>
        </NavLink>
        <NavLink to='/createEvent' onClick={second} activeclassname='selected'>
          <ListItem display='flex' alignItems='center' button disableRipple>
            <ListItemIcon>
              <img src={secondIcon} alt='secondIcon' />
            </ListItemIcon>
            <ListItemText primary='Create Event' />
          </ListItem>
        </NavLink>
        <NavLink to='/' onClick={third} activeclassname='selected'>
          <ListItem display='flex' alignItems='center' button disableRipple>
            <ListItemIcon>
              <img src={thirdIcon} alt='thirdIcon' />
            </ListItemIcon>
            <ListItemText primary='Event Management' />
          </ListItem>
        </NavLink>
        {userRole === 'teamManager' ? (
          <>
            <NavLink to='/demographics' onClick={fourth} activeclassname='selected'>
              <ListItem display='flex' alignItems='center' button disableRipple>
                <ListItemIcon>{/* <img src={thirdIcon} alt="thirdIcon" /> */}</ListItemIcon>
                <ListItemText primary='Demographics' />
              </ListItem>
            </NavLink>
          </>
        ) : (
          ''
        )}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className='app'>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            position='fixed'
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
              background: '#FFFFFF',
              color: '#009933',
            }}
            id='appBar'
          >
            <Toolbar>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon id='burguerMenu' />
              </IconButton>
              <Typography noWrap variant='h6' component='div'>
                {titleNumber === 1 ? (
                  'Event Overview'
                ) : titleNumber === 2 ? (
                  'Create Event'
                ) : titleNumber === 3 ? (
                  'Event Management'
                ) : titleNumber === 4 ? (
                  'Demographics'
                ) : titleNumber === 5 ? (
                  'Sentiments Analysis'
                ) : titleNumber === 6 ? (
                  'Topics'
                ) : (
                  <></>
                )}
              </Typography>
            </Toolbar>
          </AppBar>

          <Box
            component='nav'
            sx={{
              width: { sm: drawerWidth },
              flexShrink: { sm: 0 },
              background: '#E5FFE9',
            }}
            aria-label='mailbox folders'
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant='temporary'
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  background: '#E5FFE9',
                  border: 'none',
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant='permanent'
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  background: '#E5FFE9',
                  border: 'none',
                },
              }}
              open
            >
              <img id='logo' src={LogoPlayOff} alt='logoPlayoff' />
              {drawer}
            </Drawer>
          </Box>
          <Routes>
            {/* <Route path="/createEvent" element={<CreateEvent />} /> */}
            <Route path='/createEvent' element={<CreateEventV2 />} />
            <Route path='/' element={<EventManagement />} />
            <Route path='/editEvent' element={<EditEvent />} />
            <Route path='/eventOverview' element={<EventOverview />} />
            <Route path='/demographics' element={<Demographics />} />
            <Route path='/sentiments' element={<Sentiments />} />
            <Route path='/topics' element={<Topics />} />
          </Routes>
        </Box>
      </Router>
    </div>
  );
}

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default App;
