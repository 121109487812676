import { useState, useEffect } from "react";
import "./sentiments.css"
import { PieChart, AreaChart, Area, Pie, Sector, Tooltip, Cell, Legend, LineChart, ReferenceArea, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';
import LiveChat from "../../components/livechat/livechat";

// import LiveChat from "../../components/livechat/LiveChat";
// import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';
// import { socket } from "socket.io-client"

function Sentiments() {

    const sentimentsAnalysis = require("./sentiments.json")
    const axios = require("axios")

    const [currentEvent, setCurrentEvent] = useState(null)
    const [toggle, setToggle] = useState(false)
    const [sentimentsData, setSentimentsData] = useState(null)
    const [redditChannels, setRedditChannels] = useState(null)
    const [twitterChannels, setTwitterChannels] = useState(null)
    const [redditCheckedOptions, setRedditCheckedOptions] = useState([])
    const [twitterCheckedOptions, setTwitterCheckedOptions] = useState([])
    const [filteredRedditSentimentsData, setFilteredRedditSentimentsData] = useState([])
    const [filteredTwitterSentimentsData, setFilteredTwitterSentimentsData] = useState([])
    const [chatActivityData, setChatActivityData] = useState(null)
    const [overallSentimentsData, setOverallSentimentsData] = useState({})
    const [chatAnalysisInformation, setChatAnalysisInformation] = useState(null)
    const [refAreaIndex, setRefAreaIndex] = useState(null)
    const [refAreaLeft, setRefAreaLeft] = useState(null)
    const [refAreaRight, setRefAreaRight] = useState(null)
    const [rightXValue, setRightXValue] = useState(null)
    const [leftXValue, setLeftXValue] = useState(null)
    const [eventInfo, setEventInfo] = useState("")

    const COLORS = ['#6DAE4E', '#598E40', '#C0E9AE'];

    const toPercent = (decimal) => `${(decimal * 100).toFixed()}%`;

    const getPercent = (value, total) => {
        const ratio = total > 0 ? value / total : 0;

        return toPercent(ratio, 2);
    };


    const getEventInformation = async () => {
        try {
          const result = await axios.get(
            `${window.API_URL}/api/events/next`
          );
          setEventInfo(result.data)
          getSentimentsAnalysisInformation(result.data.eventInfo.id)
        }
        catch (err) {
          console.log(err)
        }
    }


    function zoomDataHandler(data) {
        let left_value = data.leftXValue
        let right_value = data.rightXValue
        if (left_value === null || right_value === null || left_value === right_value) {
            return data
        }
        else {
            if (left_value > right_value) {
                let result = data.filter((elem) => {
                    let splitedDate = elem.timestamp.split("h")
                    let date = new Date(null, null, null, splitedDate[0], splitedDate[1])
                    return date >= right_value && date <= left_value
                })
                if (result.length > 2) {
                    return result
                }
                else {
                    return data
                }
            }
            if (left_value < right_value) {
                let result = data.filter((elem) => {
                    let splitedDate = elem.timestamp.split("h")
                    let date = new Date(null, null, null, splitedDate[0], splitedDate[1])
                    return date <= right_value && date >= left_value
                })
                if (result.length > 2) {
                    return result
                }
                else {
                    return data
                }
            }
        }
    }

    function zoomOutReddit(index) {
        let topics_data_copy = filteredRedditSentimentsData.slice()
        topics_data_copy[index].leftXValue = null
        topics_data_copy[index].rightXValue = null
        setFilteredRedditSentimentsData(topics_data_copy)
    }

    function zoomOutTwitter(index) {
        let topics_data_copy = filteredTwitterSentimentsData.slice()
        topics_data_copy[index].leftXValue = null
        topics_data_copy[index].rightXValue = null
        setFilteredTwitterSentimentsData(topics_data_copy)
    }

    function zoomOutChat(index) {
        let topics_data_copy = chatAnalysisInformation.slice()
        topics_data_copy[index].leftXValue = null
        topics_data_copy[index].rightXValue = null
        setChatAnalysisInformation(topics_data_copy)
    }

    function setLeftValue(value, index, type) {
        setRefAreaIndex(index)
        const obj = {
            value: value,
            index: index,
            type: type
        }
        setLeftXValue(obj)
        setRefAreaLeft(value);
    }

    function setRightValue(value, index, type) {
        const obj = {
            value: value,
            index: index,
            type: type
        }
        setRightXValue(obj)
        setRefAreaRight(null)
        setRefAreaLeft(null)
    }

    useEffect(() => {
        if (leftXValue !== null && rightXValue !== null) {
            const index = leftXValue.index
            const right = rightXValue.value
            const left = leftXValue.value
            if (leftXValue.value === undefined || rightXValue.value === undefined) {
                if (leftXValue.type === "reddit") {
                    zoomOutReddit(index)
                }
                if (leftXValue.type === "youtube") {
                    zoomOutTwitter(index)
                }
                setLeftXValue(null)
                setRightXValue(null)
                return
            }
            if (leftXValue.value === rightXValue.value) {
                setLeftXValue(null)
                setRightXValue(null)
                return
            }
            if (leftXValue.type === "reddit") {
                let topics_data_copy = filteredRedditSentimentsData.slice()
                let leftSplitedDate = left.split("h")
                let rightSplitedDate = right.split("h")
                let leftDate = new Date(null, null, null, leftSplitedDate[0], leftSplitedDate[1])
                let rightDate = new Date(null, null, null, rightSplitedDate[0], rightSplitedDate[1])
                topics_data_copy[index].rightXValue = rightDate
                topics_data_copy[index].leftXValue = leftDate
                setFilteredRedditSentimentsData(topics_data_copy)
                setLeftXValue(null)
                setRightXValue(null)
            }
            if (leftXValue.type === "youtube") {

                let topics_data_copy = filteredTwitterSentimentsData.slice()
                let leftSplitedDate = left.split("h")
                let rightSplitedDate = right.split("h")
                let leftDate = new Date(null, null, null, leftSplitedDate[0], leftSplitedDate[1])
                let rightDate = new Date(null, null, null, rightSplitedDate[0], rightSplitedDate[1])
                topics_data_copy[index].rightXValue = rightDate
                topics_data_copy[index].leftXValue = leftDate
                setFilteredTwitterSentimentsData(topics_data_copy)
                setLeftXValue(null)
                setRightXValue(null)
            }
            if (leftXValue.type === "chat") {

                let topics_data_copy = chatAnalysisInformation.slice()
                let leftSplitedDate = left.split("h")
                let rightSplitedDate = right.split("h")
                let leftDate = new Date(null, null, null, leftSplitedDate[0], leftSplitedDate[1])
                let rightDate = new Date(null, null, null, rightSplitedDate[0], rightSplitedDate[1])
                topics_data_copy[index].rightXValue = rightDate
                topics_data_copy[index].leftXValue = leftDate
                setChatAnalysisInformation(topics_data_copy)
                setLeftXValue(null)
                setRightXValue(null)
            }
        }
    }, [leftXValue, rightXValue])

    //VERIFY IF IS AN EVENT ON GOING

    // async function getEventsInformation() {
    //     const { err, result } = await axios.get(
    //         `${window.API_URL}/api/events/next`
    //     );
    //     if (err || !result) {
    //         console.error(' -- Error retrieving all messages:', err);
    //         return;
    //     }
    //     else {
    //         if (result.ongoingEvent) {
    //         setToggle(true)
    //     }
    //     else {
    //         setToggle(false)
    //     }

    //     }

    //     setCurrentEvent(result.eventInfo);



    function returnChannelName(channel) {
        return channel.split("/")[4] + " / " + channel.split("/")[6] + " / " + channel.split("/")[7]
    }



    async function getSentimentsAnalysisInformation(event_id) {
        try {
            const result = await axios.get(
                `${window.API_URL}/api/sentiments/${event_id}`
            );
            let redditChannelsArray = []
            let twitterChannelsArray = []
            let chatArray = []
            result.data.channels.map((channel) => {
                if (channel.includes("reddit")) {
                    redditChannelsArray.push(channel)
                }
                if (channel.includes("youtube")) {
                    twitterChannelsArray.push(channel)
                }
                if (channel === "chat") {
                    let resArray = []
                    result.data.messages[result.data.channels.indexOf(channel)].map((message) => {
                        resArray.push(message)
                    })
                    chatArray.push(resArray)
                }
            })

            setRedditChannels(redditChannelsArray)
            setTwitterChannels(twitterChannelsArray)
            setSentimentsData(result.data)
            setChatAnalysisInformation(chatArray)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getEventInformation()
    }, [])

    const changeHandler = (e) => {
        if (e.target.value.split("/").length > 1) {
            if (redditCheckedOptions.length > 0) {
                if (!redditCheckedOptions.includes(e.target.value)) {
                    setRedditCheckedOptions(checkedOptions => [...checkedOptions, e.target.value])
                }
                else {
                    setRedditCheckedOptions(checkedOptions => checkedOptions.filter((option) => { return option !== e.target.value }))
                }
            }
            else {
                setRedditCheckedOptions([e.target.value])
            }
        }

        if (e.target.value.split("#").length > 1) {
            if (twitterCheckedOptions.length > 0) {
                if (!twitterCheckedOptions.includes(e.target.value)) {
                    setTwitterCheckedOptions(checkedOptions => [...checkedOptions, e.target.value])
                }
                else {
                    setTwitterCheckedOptions(checkedOptions => checkedOptions.filter((option) => { return option !== e.target.value }))
                }
            }
            else {
                setTwitterCheckedOptions([e.target.value])
            }
        }
    }

    useEffect(()=> {
        console.log(chatAnalysisInformation)
    },[chatAnalysisInformation])

    useEffect(() => {
        if (sentimentsData) {
            console.log(sentimentsData)
            let positiveCount = 0
            let neutralCount = 0
            let negativeCount = 0
            for (let channel of sentimentsData.messages) {
                for (let message of channel) {
                    positiveCount = positiveCount + message.POSITIVE
                    negativeCount = negativeCount + message.NEGATIVE
                    neutralCount = neutralCount + message.NEUTRAL
                }
            }
            let obj = [
                { name: "positivity", value: positiveCount },
                { name: "negativity", value: negativeCount },
                { name: "neutrality", value: neutralCount }
            ]
            setOverallSentimentsData(obj)
        }
    }, [sentimentsData])

    useEffect(() => {
        let arr = []
        for (let option of redditCheckedOptions) {
            for (let channel of sentimentsData.channels) {
                if (option === channel) {
                    arr.push(sentimentsData.messages[sentimentsData.channels.indexOf(channel)])
                }
            }
        }
        setFilteredRedditSentimentsData(arr)

    }, [redditCheckedOptions])

    useEffect(() => {
        let arr = []
        for (let option of twitterCheckedOptions) {
            for (let channel of sentimentsData.channels) {
                if (option === channel) {
                    arr.push(sentimentsData.messages[sentimentsData.channels.indexOf(channel)])
                }
            }
        }
        setFilteredTwitterSentimentsData(arr)

    }, [twitterCheckedOptions])


    return (
        <div className="sentiments-container">
            <div className="top-row">
                <div className="overall-sentiments-container">
                    <div className="header">
                        <p>OVERALL BALANCE OF SENTIMENTS</p>
                    </div>
                    <div className="main">
                        <ResponsiveContainer debounce={1000} width="100%" height="100%">
                            <PieChart width={500} height={500}>
                                <Pie
                                    data={overallSentimentsData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label
                                    outerRadius={110}
                                    fill="#8884d8"
                                    dataKey="value"
                                    nameKey="name"
                                >
                                    <Cell fill="#1A732E" />
                                    <Cell fill="#AD3131" />
                                    <Cell fill="#4699D4" />
                                </Pie>
                                <Legend />
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="chat-container">
                    <div className="header">
                        <p>LIVE CHAT</p>
                    </div>
                    <div className="main">
                        <LiveChat />
                    </div>
                </div>
            </div>
            {chatAnalysisInformation?.length > 0 ?
                <div className="middle-row">
                    <div className="header">
                        <p>CHAT INFORMATION</p>
                    </div>
                    
                    <div className="chat-information-container">
                        {chatAnalysisInformation?.map((data, index) => (
                        <div className="left-side">
                            <div className="chart-header">
                                <p>Sentiments analysis over time</p>
                                <button id="zoom-out" onClick={() => zoomOutChat(index)}>Zoom Out</button>
                            </div>
                            
                            <div className="chart">
                                <ResponsiveContainer debounce={1000} width="100%" height="100%">
                                    <AreaChart
                                        width={500}
                                        height={400}
                                        data={zoomDataHandler(data, index, "reddit")}
                                        stackOffset="expand"
                                        onMouseDown={(e) => e !== null ? setLeftValue(e.activeLabel, index, "chat") : ""}
                                        onMouseMove={(e) => setRefAreaRight(e.activeLabel)}
                                        onMouseUp={(e) => e !== null ? setRightValue(e.activeLabel, index) : ""}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="timestamp" />
                                        <YAxis tickFormatter={toPercent} />
                                        <Legend />
                                        <Tooltip />
                                        <Area type="monotone" dataKey="POSITIVE" stackId="1" stroke="#1A732E" fill="#1A732E" />
                                        <Area type="monotone" dataKey="NEUTRAL" stackId="1" stroke="#4699D4" fill="#4699D4" />
                                        <Area type="monotone" dataKey="NEGATIVE" stackId="1" stroke="#AD3131" fill="#AD3131" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        ))}
                        <div className="right-side">
                            <div className="chart-header">
                                <p>Activity over time</p>
                            </div>
                            <div className="chart">
                                <ResponsiveContainer debounce={1000} width="100%" height="100%">
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={chatActivityData}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Legend />
                                        <Tooltip />
                                        <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                                        <Line type="monotone" dataKey="uv" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div> : ""}
            <div className="bottom-row">
                <div className="header">
                    <p>SENTIMENTS ANALYSIS OVER TIME - EXTERNAL CHANNELS</p>
                </div>
                <div className="sentiments-analysis-container">
                    <div className="left-side">
                        <div className="title">
                            <p>REDDIT</p>
                        </div>
                        {redditChannels ?
                            <div className="checkboxes reddit">
                                {redditChannels.map((channel, index) => (
                                    <div style={{ display: "flex", justifyContent: "start" }}><input type="checkbox" onChange={changeHandler} id={`reddit-channel-${index}`} name={`reddit-channel-${index}`} value={channel}></input>
                                        <label htmlFor={`reddit-channel-${index}`}>{returnChannelName(channel)}</label></div>
                                ))}
                            </div> : <></>}{
                            filteredRedditSentimentsData?.map((data, index) => (
                                <div className="charts" key={index}>
                                    <div className="chart-header sentiments">
                                        <p>{data[0].channel}</p>
                                        <button id="zoom-out" onClick={() => zoomOutReddit(index)}>Zoom Out</button>
                                    </div>
                                    <div className="chart">

                                        <ResponsiveContainer debounce={1000} width="100%" height="100%">
                                            <AreaChart
                                                width={500}
                                                height={400}
                                                data={zoomDataHandler(data, index, "reddit")}
                                                stackOffset="expand"
                                                onMouseDown={(e) => e !== null ? setLeftValue(e.activeLabel, index, "reddit") : ""}
                                                onMouseMove={(e) => setRefAreaRight(e.activeLabel)}
                                                onMouseUp={(e) => e !== null ? setRightValue(e.activeLabel, index) : ""}
                                                margin={{
                                                    top: 10,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="timestamp" />
                                                <YAxis tickFormatter={toPercent} />
                                                <Legend />
                                                <Tooltip />
                                                <Area type="monotone" dataKey="POSITIVE" stackId="1" stroke="#1A732E" fill="#1A732E" />
                                                <Area type="monotone" dataKey="NEUTRAL" stackId="1" stroke="#4699D4" fill="#4699D4" />
                                                <Area type="monotone" dataKey="NEGATIVE" stackId="1" stroke="#AD3131" fill="#AD3131" />
                                                {refAreaLeft && refAreaRight && refAreaIndex === index ? (
                                                    <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                                                ) : null}
                                            </AreaChart>
                                        </ResponsiveContainer>

                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="right-side">
                        <div className="title">
                            <p>YOUTUBE</p>
                        </div>
                        {twitterChannels ?
                            <div className="checkboxes reddit">
                                {twitterChannels.map((channel, index) => (
                                    <div style={{ display: "flex", justifyContent: "start" }}><input type="checkbox" onChange={changeHandler} id={`youtube-channel-${index}`} name={`youtube-channel-${index}`} value={channel}></input>
                                        <label htmlFor={`youtube-channel-${index}`}>{channel}</label></div>
                                ))}
                            </div> : <></>
                        }{
                            filteredTwitterSentimentsData?.map((data, index) => (
                                <div className="charts" key={index}>
                                    <div className="chart-header sentiments">
                                        <p>{"#" + data[0].channel.split("#")[1]}</p>
                                        <button id="zoom-out" onClick={() => zoomOutTwitter(index)}>Zoom Out</button>
                                    </div>
                                    <div className="chart">

                                        <ResponsiveContainer debounce={1000} width="100%" height="100%">
                                            <AreaChart
                                                width={500}
                                                height={400}
                                                data={zoomDataHandler(data, index, "youtube")}
                                                stackOffset="expand"
                                                onMouseDown={(e) => e !== null ? setLeftValue(e.activeLabel, index, "youtube") : ""}
                                                onMouseMove={(e) => setRefAreaRight(e.activeLabel)}
                                                onMouseUp={(e) => e !== null ? setRightValue(e.activeLabel, index) : ""}
                                                margin={{
                                                    top: 10,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="timestamp" />
                                                <YAxis tickFormatter={toPercent} />
                                                <Legend />
                                                <Tooltip />
                                                <Area type="monotone" dataKey="POSITIVE" stackId="1" stroke="#1A732E" fill="#1A732E" />
                                                <Area type="monotone" dataKey="NEUTRAL" stackId="1" stroke="#4699D4" fill="#4699D4" />
                                                <Area type="monotone" dataKey="NEGATIVE" stackId="1" stroke="#AD3131" fill="#AD3131" />
                                                {refAreaLeft && refAreaRight && refAreaIndex === index ? (
                                                    <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                                                ) : null}
                                            </AreaChart>
                                        </ResponsiveContainer>

                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sentiments