import React, { useState, useEffect } from 'react';
import './EventTable.css';
import Table from '@mui/material/Table';
import ReactTagInput from '@pathofdev/react-tag-input';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AirplayOutlinedIcon from '@mui/icons-material/AirplayOutlined';
import Rodal from 'rodal';

const EventTable = ({ props }) => {
  const axios = require('axios');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [redditTags, setRedditTags] = useState([]);
  const [twitterTags, setTwitterTags] = useState([]);

  function showModal() {
    setIsModalOpen(true);
  }

  function handleForm() {
    const newObject = { ...selectedEvent, reddittagid: redditTags };
    const newObject2 = { ...newObject, twittertagid: twitterTags };

    updateEvent(newObject2);
  }

  async function updateEvent(newEvent) {
    let redditChannelsFormatted = '';
    for (let channel of newEvent.reddittagid) {
      redditChannelsFormatted += channel + ',';
    }

    let twitterChannelsFormatted = '';
    for (let channel of newEvent.twittertagid) {
      twitterChannelsFormatted += channel + ',';
    }

    redditChannelsFormatted = redditChannelsFormatted.substring(0, redditChannelsFormatted.length - 1);
    twitterChannelsFormatted = twitterChannelsFormatted.substring(0, twitterChannelsFormatted.length - 1);
    try {
      const result = await axios.put(`${window.API_URL}/api/admin/events/${newEvent.id}`, {
        fixtureid: newEvent.fixtureid,
        hometeamname: newEvent.hometeamname,
        hometeamlogo: newEvent.hometeamlogo,
        awayteamname: newEvent.awayteamname,
        awayteamlogo: newEvent.awayteamlogo,
        country: newEvent.country,
        leaguename: newEvent.leaguename,
        sporttype: newEvent.sporttype,
        startdate: newEvent.startdate,
        enddate: newEvent.enddate,
        reddittagid: redditChannelsFormatted,
        twittertagid: twitterChannelsFormatted,
        address: newEvent.address,
        latitude: newEvent.latitude,
        longitude: newEvent.longitude,
        radius: newEvent.radius,
        isanalysisstarted: newEvent.isanalysisstarted,
        iseventstarted: newEvent.iseventstarted,
        ismatchstarted: newEvent.ismatchstarted,
        isintervalstarted: newEvent.isintervalstarted,
      });
      showConfirmationModal();
    } catch (err) {
      console.log(err);
    }
  }

  function redditTagsFormatter(selectedEvent) {
    let arr = selectedEvent.reddittagid.split(',');
    setRedditTags(arr);
  }

  function twitterTagsFormatter(selectedEvent) {
    let arr = selectedEvent.twittertagid.split(',');
    setTwitterTags(arr);
  }

  function showConfirmationModal() {
    setIsModalOpen(false);
    setIsConfirmationModalOpen(true);
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }

  useEffect(() => {
    console.log(selectedEvent);
  }, [selectedEvent]);

  function hideModal() {
    setIsModalOpen(false);
  }

  function hideConfirmationModal() {
    setIsConfirmationModalOpen(false);
  }

  return (
    <div className='event_table'>
      <TableContainer className='table-container' sx={{ maxWidth: '80%' }} component={Paper}>
        <Table size='medium' className='table' arial-label='Events table'>
          <TableHead className='tableHead'>
            <TableRow>
              <TableCell align='center'>See More</TableCell>
              <TableCell align='center'>Sport</TableCell>
              <TableCell align='center'>Start</TableCell>
              <TableCell align='center'>End</TableCell>
              <TableCell align='center'>Area</TableCell>
              <TableCell align='center'>Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell scope='row' align='center'>
                  <AirplayOutlinedIcon onClick={(e) => props.handleSeeMore(e, row.id)} />
                </TableCell>
                <TableCell scope='row' align='center'>
                  {row.sporttype}
                </TableCell>
                <TableCell scope='row' align='center'>
                  {row.startdate}
                </TableCell>
                <TableCell scope='row' align='center'>
                  {row.enddate}
                </TableCell>
                <TableCell scope='row' sx={{ maxWidth: '30%' }} align='center'>
                  <strong>Location:</strong>&nbsp;{row.address}
                  <br />
                  <strong>Range:</strong>&nbsp;{row.radius}
                </TableCell>

                <TableCell scope='row' align='center'>
                  <span>
                    {/*<ModeEditOutlineOutlinedIcon
                      onClick={() => {
                        setSelectedEvent(row);
                        redditTagsFormatter(row);
                        twitterTagsFormatter(row);
                        showModal();
                      }}
                    />*/}
                    <DeleteOutlineOutlinedIcon
                      sx={{ color: 'red', margin: 'auto' }}
                      onClick={(e) => props.handleEventDelete(e, row.id)}
                    />
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Rodal visible={isModalOpen} className='modal' onClose={hideModal}>
        <div className='header'>
          <h3>Edit Event</h3>
        </div>
        <div className='content'>
          <div className='id inputs'>
            <label htmlFor='id'>Event ID: </label>
            <input type='text' id='id' disabled value={selectedEvent.id}></input>
          </div>
          <div className='sport inputs'>
            <label htmlFor='sport'>Sport: </label>
            <input type='text' id='sport' disabled value={selectedEvent.sporttype}></input>
          </div>
          <div className='start inputs'>
            <label htmlFor='start'>Start: </label>
            <input type='text' id='start' disabled value={selectedEvent.startdate}></input>
          </div>
          <div className='end inputs'>
            <label htmlFor='end'>End: </label>
            <input type='text' id='end' disabled value={selectedEvent.enddate}></input>
          </div>
          <div className='area inputs'>
            <label htmlFor='area'>Area: </label>
            <input type='text' id='area' disabled value={selectedEvent.address}></input>
          </div>
          <div className='range inputs'>
            <label htmlFor='range'>Range: </label>
            <input
              type='text'
              id='range'
              onChange={(event) => {
                setSelectedEvent({ ...selectedEvent, radius: event.target.value });
              }}
              value={selectedEvent.radius}
            ></input>
          </div>
          {/*<div className="reddit">
            <label htmlFor="redditChannel">Reddit:  </label>
            <ReactTagInput
              id="redditChannel"
              removeOnBackspace="true"
              tags={redditTags}
              maxTags={5}
              placeholder="Type a channel and press enter"
              editable={true}
              readOnly={false}
              required
              onChange={(newTags) => setRedditTags(newTags)}
            />
          </div>
          <div className="youtube">

            <label htmlFor="twitterChannel">Youtube:  </label>
            <ReactTagInput
              id="twitterChannel"
              removeOnBackspace="true"
              tags={twitterTags}
              maxTags={5}
              placeholder="Type a channel and press enter"
              editable={true}
              readOnly={false}
              required
              onChange={(newTags) => setTwitterTags(newTags)}
            />
          </div>*/}
        </div>
        <div className='footer'>
          <button type='button' onClick={hideModal} className='cancel'>
            Cancel
          </button>
          <button type='button' className='confirm' onClick={handleForm}>
            Confirm
          </button>
        </div>
      </Rodal>
      <Rodal visible={isConfirmationModalOpen} className='confirmation-modal' onClose={hideConfirmationModal}>
        <div className='header'>
          <h3>Edit Event</h3>
        </div>
        <div className='content'>
          <p>Event was edited with success!</p>
        </div>
        <div className='footer'>
          <button type='text' className='close' onClick={hideConfirmationModal}>
            Close
          </button>
        </div>
      </Rodal>
    </div>
  );
};

export default EventTable;
