import React, { useState } from 'react'
import './StatusCard.css'
import { Grid, TextField } from "@mui/material";
import List from "@mui/material/List";
import ListItem, { getListItemUtilityClass } from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const StatusCard = ({props}) => {
  
    console.log(props)

    return (
        <div className="card" id="statusCard">
        <div className="cardTitle">
          <h2>STATUS</h2>
        </div>
        <div className="cardContent">
          <Grid id="outer-div" container spacing={2}>
            <Grid item lg={6}>
              <h3>Event Status</h3>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <span id="eventStartDot" style={{backgroundColor: props.iseventstarted ? "#009933" : "#FF0000"}} className="dot"></span>
                  </ListItemIcon>
                  <ListItemText primary="Event start" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <span id="matchStartDot" style={{backgroundColor: props.ismatchstarted ? "#009933" : "#FF0000"}} className="dot"></span>
                  </ListItemIcon>
                  <ListItemText primary="Match start" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <span id="intervalStartDot" style={{backgroundColor: props.isintervalstarted ? "#009933" : "#FF0000"}} className="dot"></span>
                  </ListItemIcon>
                  <ListItemText primary="Match interval" />
                </ListItem>
              </List>
            </Grid>
            <Grid item lg={6}>
              <h3>App Status</h3>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <span id="streamingDot" style={{backgroundColor: props.iseventstarted ? "#009933" : "#FF0000"}} className="dot"></span>
                  </ListItemIcon>
                  <ListItemText primary="Live Streaming" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <span id="socialMediaDot" style={{backgroundColor: props.isanalysisstarted ? "#009933" : "#FF0000"}} className="dot"></span>
                  </ListItemIcon>
                  <ListItemText primary="Social media analysis" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </div>
    )
}

export default StatusCard;