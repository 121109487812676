import React, {useEffect, useState} from 'react';
import './ActionModal.css'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ActionModal = ({props}) => {
    return (
        <Modal
            className="Modal"
            open={props.isOpen}
            onClose={props.handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="ModalBox">
              <Typography variant="h6" component="h2">
                {props.label}
              </Typography>
              <div className="btns">
                <button className="approvalBtn" onClick={(e) => props.handleEvent(e)}>Yes</button>
                <button className="cancelBtn" onClick={props.handleModalClose}>No</button>
              </div>
            </Box>
          </Modal>
    )
}

export default ActionModal;