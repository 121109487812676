import React, { useState, useEffect } from 'react';
import './editEvent.css';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { FormControl, FormLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ReactTagInput from '@pathofdev/react-tag-input';
import Map from '../../components/Map/Map';
import axios from 'axios';

const drawerWidth = 240;

const EditEvent = () => {
  const { state } = useLocation();
  const [addFormData, setAddFormData] = useState({});
  const [redditTags, setRedditTags] = useState([]);
  const [twitterTags, setTwitterTags] = useState([]);

  //API data
  const [sports, setSports] = useState([
    {
      id: 1,
      name: 'Football',
    },
    {
      id: 2,
      name: 'Basketball',
    },
    {
      id: 3,
      name: 'MMA',
    },
  ]);

  const autocompleteStyle = {
    '& .MuiAutocomplete-inputRoot': {
      border: 0,
      width: '100%',
      height: '32px',
      background: '#EFEFEF',
      borderRadius: '4px',
      color: '#767676',
      fontFamily: 'Heebo, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      padding: '5px',
      outline: 0,
      '& .MuiAutocomplete-input': {
        padding: '0px !important',
        '&::placeholder': {
          color: '#767676',
          opacity: 1,
        },
      },
      '& .MuiAutocomplete-endAdornment': {
        paddingRight: '0px',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .Mui-focused': {
        outline: 'none',
      },
    },
  };

  const getState = (value) => {
    console.log(value);
    setEventAddress(value.address);
    setEventLat(value.lat);
    setEventLng(value.lng);
    setEventRadius(value.radius);
  };

  const [eventData, setEventData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [competitionData, setCompetitionData] = useState({});
  const [teams, setTeams] = useState([]);
  const [eventAddress, setEventAddress] = useState('');
  const [eventLat, setEventLat] = useState();
  const [eventLng, setEventLng] = useState();
  const [eventRadius, setEventRadius] = useState();

  useEffect(() => {
    if (state) {
      getEventById(state);
    }
  }, []);

  async function getEventById(eventId) {
    try {
      const { data } = await axios.get(`${window.API_URL}/api/admin/events/${eventId}`);
      setAddFormData({
        fixtureId: data.fixtureId,
        eventSportType: data.sporttype,
        eventStartDate: data.startdate,
        eventEndDate: data.enddate,
        redditTags: data.reddittagid,
        twitterTags: data.twittertagid,
        address: data.address,
        lat: data.latitude,
        lng: data.longitude,
        radius: data.radius,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleFormChange = (event) => {
    event.preventDefault();
    const name = event.target.getAttribute('name');
    let value = '';
    if (event.target.value == 0) {
      value = event.target.textContent;
    } else {
      value = event.target.value;
    }

    const newFormData = { ...addFormData };
    newFormData[name] = value;
  };

  return (
    <div>
      <Box
        className='createEventMainBox'
        component='main'
        sx={{
          flexGrow: 1,
          p: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <form className='createEventForm'>
          {/* <form className="createEventForm" onSubmit={handleFormSubmit}> */}
          <Grid container spacing='30px'>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel id='eventNameLabel' component='legend' htmlFor='txtEventName'>
                Event Name
              </FormLabel>
              <input
                id='txtEventName'
                type='text'
                name='eventName'
                value={addFormData.eventName ? addFormData.eventName : ''}
                onChange={handleFormChange}
                placeholder='Insert the event name'
                required
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel id='eventSportLabel' component='legend' htmlFor='selectEventSport'>
                Sport Type
              </FormLabel>
              <Autocomplete
                id='sportTypeAutocomplete'
                options={sports}
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={handleFormChange}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    onChange={handleFormChange}
                    value={option.name}
                    name='eventSportType'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='eventSportType'
                    variant='outlined'
                    placeholder='Select the sport type'
                    sx={autocompleteStyle}
                    required
                    onChange={handleFormChange}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel id='eventCountryLabel' component='legend'>
                Country
              </FormLabel>
              <Autocomplete
                id='countryAutocomplete'
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={handleFormChange}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    onChange={handleFormChange}
                    value={option.name}
                    name='eventCountry'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='eventCountry'
                    variant='outlined'
                    placeholder='Select the country'
                    sx={autocompleteStyle}
                    required
                    onChange={handleFormChange}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel id='eventCompetitionLabel' component='legend'>
                Competition
              </FormLabel>
              <Autocomplete
                id='competitionAutocomplete'
                options={competitions}
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={handleFormChange}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    onChange={handleFormChange}
                    value={option.id}
                    name='eventCompetition'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='eventCompetition'
                    variant='outlined'
                    placeholder='Select the competition'
                    sx={autocompleteStyle}
                    required
                    onChange={handleFormChange}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel id='eventFirstTeamLabel' component='legend'>
                1st Team Name
              </FormLabel>
              <Autocomplete
                id='firstTeamAutocomplete'
                options={teams}
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={handleFormChange}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    onChange={handleFormChange}
                    value={option.id}
                    name='eventFirstTeam'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='eventFirstTeam'
                    placeholder='Select the first team name'
                    variant='outlined'
                    sx={autocompleteStyle}
                    required
                    onChange={handleFormChange}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel id='eventSecondTeamLabel' component='legend'>
                2nd Team Name
              </FormLabel>
              <Autocomplete
                id='secondTeamAutocomplete'
                options={teams}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => {
                  if (option.id == addFormData.eventFirstTeam) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                onChange={handleFormChange}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    onChange={handleFormChange}
                    value={option.id}
                    name='eventSecondTeam'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Select the second team name'
                    name='eventSecondTeam'
                    variant='outlined'
                    sx={autocompleteStyle}
                    required
                    onChange={handleFormChange}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel id='eventStartDateLabel' component='legend' htmlFor='selectEventStartDate'>
                Start Date
              </FormLabel>
              <input id='startDate' type='datetime-local' name='eventStartDate' onChange={handleFormChange} required />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel id='eventEndDateLabel' component='legend' htmlFor='selectEventEndDate'>
                End Date
              </FormLabel>
              <input
                id='endDate'
                type='datetime-local'
                name='eventEndDate'
                onChange={handleFormChange}
                //placeholder="Insert the player's name"
                required
              />
            </Grid>
            {/*<Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel
                id="eventRedditChannelLabel"
                component="legend"
                htmlFor="redditChannel"
              >
                Reddit Channels
              </FormLabel>
              <ReactTagInput
                id="redditChannel"
                tags={redditTags}
                maxTags={5}
                placeholder="Type a channel and press enter"
                editable={true}
                readOnly={false}
                required
                onChange={(newTags) => setRedditTags(newTags)}
              />
              <h6>
                Reddit Channels use for social media analysis concerning this
                event. You must provide one or more URLs of Reddit threads.
              </h6>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <FormLabel
                id="eventTwitterChannelLabel"
                component="legend"
                htmlFor="twitterChannel"
              >
                Youtube Channels
              </FormLabel>
              <ReactTagInput
                id="twitterChannel"
                tags={twitterTags}
                maxTags={5}
                placeholder="Type a channel and press enter"
                editable={true}
                readOnly={false}
                required
                onChange={(newTags) => setTwitterTags(newTags)}
              />
              <h6>
                Youtube channels used for social media analysis concerning this
                event. You must provide one or more hashtags of Youtube.
              </h6>
              </Grid>*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormLabel id='eventMapLabel' component='legend' htmlFor='map'>
                Event Location
              </FormLabel>
              {/* <Map liftingStateUpHandler={getState} /> */}
              <Map liftingStateUpHandler={getState} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <input
                type='submit'
                value='+ Create Event'
                // onClick={handleFormSubmit}
                className='createEventSubmitBtn'
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default EditEvent;
