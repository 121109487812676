import { useState, useEffect } from "react";
import "./demographics.css"
import { PieChart, Pie, Sector, Tooltip, Cell, Legend, LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';

function Demographics() {

    const [usersGenderInformation, setUsersGenderInformation] = useState(null)
    const [usersAgeInformation, setUsersAgeInformation] = useState(null)

    const axios = require("axios")

    const ageGroupData = [
        { name: '<14 Years', value: 17 },
        { name: '15-29 Years', value: 49 },
        { name: '30-44 Years', value: 19 },
        { name: '45-59 Years', value: 10 },
        { name: '>60 Years', value: 5 },
    ];

    const COLORS = ['#4699D4', '#C14991', '#FBD655'];
    const COLORS2 = ['#4699D4', '#C14991', '#FBD655', '#6DAE4E', '#353535'];


    async function getUsersGenders() {
        try {
            const result = await axios.get(
                `${window.API_URL}/api/users/gender`
            );
            setUsersGenderInformation(result.data)
            // setTopics(result.data.channels)
        }
        catch (err) {
            console.log(err)
        }
    }

    async function getUsersAge() {
        try {
            const result = await axios.get(
                `${window.API_URL}/api/users/age`
            );
            setUsersAgeInformation(result.data)
            // setTopics(result.data.channels)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(()=> {
        getUsersGenders()
        getUsersAge()
    },[])


    const customLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if(percent > 0) {
            return (
                <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
                  {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        }
        else {
            return
        }
    }



    return (
        <div className="demographics-container">
            <div className="top-row">
                <div className="gender-breakdown-container">
                    <div className="header">
                        <p>GENDER BREAKDOWN</p>
                    </div>
                    <div className="main">
                        <ResponsiveContainer debounce={1000} width="100%" height="100%">
                            <PieChart width={800} height={800}>
                                <Pie
                                    data={usersGenderInformation}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={customLabel}
                                    outerRadius={120}
                                    fill="#8884d8"
                                    dataKey="count"
                                    nameKey="name"
                                >
                                    {usersGenderInformation?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Legend />
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="age-group-container">
                    <div className="header">
                        <p>AGE GROUP BREAKDOWN</p>
                    </div>
                    <div className="main">
                        <ResponsiveContainer debounce={1000} width="100%" height="100%">
                            <PieChart width={800} height={800}>
                                <Pie
                                    data={usersAgeInformation}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={customLabel}
                                    outerRadius={120}
                                    fill="#8884d8"
                                    dataKey="value"
                                    nameKey="name"
                                >
                                    {ageGroupData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Demographics