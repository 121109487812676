import React, { useEffect, useState } from 'react';
import './eventManagement.css';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import emptyState from '../../assets/emptyState.svg';
import ReactPaginate from 'react-paginate';
import EventTable from '../../components/EventTable/EventTable';

const drawerWidth = 240;

const EventManagement = () => {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [pages, setPages] = useState();
  const [currentItems, setCurrentItems] = useState([]);
  const [value, setValue] = useState('');
  const [holder, setHolder] = useState([]);
  const [filterData, setFilterData] = useState({
    eventName: '',
    eventSport: '',
  });

  useEffect(() => {
    getAllEvents();
  }, []);

  useEffect(() => {
    setPages(Math.ceil(rows.length / itemsPerPage));
    getPageData(pageNumber);
  }, [rows]);

  async function getAllEvents() {
    try {
      const { data } = await axios.get(`${window.API_URL}/api/admin/events`);
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        let startDate = new Date(data[i].startdate);
        let endDate = new Date(data[i].enddate);
        data[i].startdate =
          startDate.getFullYear() +
          '/' +
          parseFloat(startDate.getMonth() + 1) +
          '/' +
          startDate.getDate() +
          ' ' +
          startDate.getHours() +
          ':' +
          startDate.getMinutes() +
          ':' +
          startDate.getSeconds();
        data[i].enddate =
          endDate.getFullYear() +
          '/' +
          parseFloat(endDate.getMonth() + 1) +
          '/' +
          endDate.getDate() +
          ' ' +
          endDate.getHours() +
          ':' +
          endDate.getMinutes() +
          ':' +
          endDate.getSeconds();
      }

      setRows(data);
      setHolder(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteEvent(eventId) {
    try {
      setRows(rows.filter((row) => row.id != eventId));
      setPageNumber(0);
      axios.delete(`${window.API_URL}/api/admin/events/${eventId}`);
    } catch (error) {
      console.log(error);
    }
  }

  const getPageData = (page) => {
    const indexOfLastItem = page * itemsPerPage;
    setCurrentItems(rows.slice(indexOfLastItem, indexOfLastItem + itemsPerPage));
  };

  useEffect(() => {
    console.log(currentItems);
  }, [currentItems]);

  const changePage = (event) => {
    setPageNumber(event.selected + 1);
    getPageData(event.selected);
  };

  const handleEventUpdate = (event, eventId) => {
    event.preventDefault();
    navigate('/editEvent', { state: eventId });
  };

  const handleEventDelete = (event, eventId) => {
    event.preventDefault();
    deleteEvent(eventId);
  };

  const handleSeeMore = (event, eventId) => {
    event.preventDefault();
    navigate('/eventOverview', { state: eventId });
  };

  const navigateToCreate = (event) => {
    event.preventDefault();
    navigate('/createEvent');
  };

  const handleChange = (event) => {
    const name = event.target.getAttribute('name');
    const value = event.target.value;

    if (name == 'eventName') {
      setValue(event.target.value);
    }

    const newFilterData = { ...filterData };
    newFilterData[name] = value;

    setFilterData(newFilterData);
    handleFilterData(newFilterData);
  };

  const handleFilterData = (filterData) => {
    if (filterData.eventName == '' && filterData.eventSport == '') {
      setRows(holder);
    } else {
      if (filterData.eventName != '' && filterData.eventSport == '') {
        setRows(
          holder.filter(
            (row) => row.name.toUpperCase().includes(filterData.eventName.toUpperCase()) || filterData.eventName === ''
          )
        );
        setPageNumber(0);
      }
      if (filterData.eventName == '' && filterData.eventSport != '') {
        setRows(holder.filter((row) => row.sporttype == filterData.eventSport));
        setPageNumber(0);
      }
      if (filterData.eventName != '' && filterData.eventSport != '') {
        setRows(
          holder.filter(
            (row) =>
              (row.name.toUpperCase().includes(filterData.eventName.toUpperCase()) || filterData.eventName === '') &&
              row.sporttype == filterData.eventSport
          )
        );
        setPageNumber(0);
      }
    }
  };

  return (
    <div>
      {holder.length > 0 ? (
        <Box
          className='eventTableBox'
          component='main'
          sx={{
            flexGrow: 1,
            p: 0,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <div id='gridDiv'>
            <Grid container id='filterGrid' spacing='30px'>
              {/*<Grid item lg={6} md={12} sm={12} xs={12}>
            <h2>Filter Name</h2>
            <input
              type="text"
              id="txtName"
              onChange={handleChange}
              name="eventName"
              value={value}
              placeholder="Insert name to filter"
            />
            </Grid>*/}
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <h2>Filter Sport</h2>
                <select id='selectSport' name='eventSport' onChange={handleChange}>
                  <option value=''>Choose one sport</option>
                  <option value='Football'>Football</option>
                  <option value='Basketball'>Basketball</option>
                </select>
              </Grid>
            </Grid>
          </div>
          <EventTable
            props={{
              data: currentItems,
              handleSeeMore: handleSeeMore,
              handleEventUpdate: handleEventUpdate,
              handleEventDelete: handleEventDelete,
            }}
          />
          <div id='paginationDiv'>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              pageCount={pages}
              onPageChange={changePage}
              containerClassName={'paginationBtns'}
              previousLinkClassName={'controllerBtn'}
              nextLinkClassName={'controllerBtn'}
              activeClassName={'activePagination'}
            />
          </div>
        </Box>
      ) : (
        <Box
          className='emptyStateBox'
          component='main'
          sx={{
            flexGrow: 1,
            p: 0,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <img className='emptyStateImg' src={emptyState} />
          <h1>You haven't created any events yet</h1>
          <h3>To start creating your events, click in the button down below.</h3>
          <button id='emptyStateBtn' onClick={navigateToCreate}>
            Create Event
          </button>
        </Box>
      )}
    </div>
  );
};

export default EventManagement;
