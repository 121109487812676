import React, { useEffect, useState } from 'react';

import ChatMessage from './chatmessage/ChatMessage';
import io from 'socket.io-client';

const socket = io(window.API_WS_HOST, { path: window.API_WS_PATH + '/socket.io' });

function LiveChat() {
  const axios = require('axios');
  // const user = useSelector((state) => state.user);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getAllMessages() {
    const { err, data } = await axios.get(`${window.API_URL}/api/messages`);
    if (err || !data) {
      console.error(' -- Error retrieving all messages:', err);
      return;
    }

    setMessages(data);
    setLoading(false);
  }

  useEffect(() => {
    // if (hasFetchedData.current) { return; }
    // hasFetchedData.current = true;

    socket.on('newMessage', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    getAllMessages();
  }, []);

  useEffect(() => {
    console.log('Messages: ', messages);
  }, [messages]);

  // useEffect(() => {
  //     getAllMessages();
  // },[]);

  return (
    <div>
      {messages.map((message, index) => (
        <ChatMessage key={index} username={message.username} content={message.content} />
      ))}
    </div>
  );
}

export default LiveChat;
