import React from 'react';
import { Emoji, getEmojiDataFromNative } from 'emoji-mart';
import { unicodeEmojiRegexp } from 'draft-js-emoji-mart-plugin/lib/constants';

export const parseContent = (content) => {
    let match = null;
    let lastOffset = 0;
    const parts = [];

    // Execute a search for a match in chat message content.
    while ((match = unicodeEmojiRegexp.exec(content)) !== null) {
        parts.push(content.substring(lastOffset, match.index));
        lastOffset = match.index + match[0].length;

        // const emoji = getEmojiDataFromNative(match[0], 'apple', emoji_data);

        // if (emoji) {
        //     parts.push(<Emoji emoji={emoji.id} size={18} />);
        // } else {
        //     parts.push(match[0]);
        // }
        parts.push(match[0]);
    }

    parts.push(content.substring(lastOffset, content.length));

    return (
        <span>
            {parts.map((part, i) => <span key={i}>{part}</span>)}
        </span>
    );
}