import "./topics.css"


import React, { PureComponent, useState, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, ReferenceArea, Line, Legend, ResponsiveContainer } from 'recharts';
import { getRandomColor } from "../../utils/colors";
import * as moment from 'moment'




function Topics() {

    const axios = require("axios")
    const [topicsInformation, setTopicsInformation] = useState(null)
    const [topics, setTopics] = useState(null)
    const [overallTopics, setOverallTopics] = useState(null)
    const [filteredTopicsData, setFilteredTopicsData] = useState(null)
    const [checkedOptions, setCheckedOptions] = useState([])
    const [allMessagesTogether, setAllMessagesTogether] = useState(null)
    const [topicMap, setTopicMap] = useState(null)
    const [leftXValue, setLeftXValue] = useState(null)
    const [rightXValue, setRightXValue] = useState(null)
    const [refAreaLeft, setRefAreaLeft] = useState(null);
    const [refAreaRight, setRefAreaRight] = useState(null);
    const [isDataAvailable, setIsDataAvailable] = useState(true)
    const [refAreaIndex, setRefAreaIndex] = useState(true)
    const [eventInfo, setEventInfo] = useState("")



    function createTopicMap(index, msg, topicMap, topicName) {
        let topicList = {
            array: [],
            leftXValue: null,
            rightXValue: null
        }
        console.log("MESSAGE: ", msg)
        const channel = index
        const timestamp = msg.timestamp
        const time = msg.time
        let topic = topicName
        let n = msg.n

        const value = msg[topicName]
        if (!topicMap.has(topicName)) {
            const message = {
                timestamp: timestamp,
                time: time
            }
            message[channel] = value
            message["topic"] = topic
            message["n"] = n
            message["message_count"] = value * n
            topicList.array.push(message)

            topicMap.set(topicName, topicList)
        } else {
            topicList = topicMap.get(topicName)
            const oldMessage = topicList.array.filter((message) => (message.time === time))
            if (!oldMessage.length) {
                const message = {
                    timestamp: timestamp,
                    time: time
                }
                message[channel] = value
                message["topic"] = topic
                message["n"] = n
                message["message_count"] = value * n
                topicList.array.push(message)
                topicMap.set(topicName, topicList)
            } else {
                for (let topic of topicList.array) {
                    if (topic.time === time) {
                        topic[channel] = value
                    }
                }
                topicMap.set(topicName, topicList)
            }
        }
        return topicMap
    }

    const getEventInformation = async () => {
        try {
          const result = await axios.get(
            `${window.API_URL}/api/events/next`
          );
          setEventInfo(result.data)
          getTopicsInformation(result.data.eventInfo.id)
          getOverallTopicsInformation(result.data.eventInfo.id)
        }
        catch (err) {
          console.log(err)
        }
    }

    const CustomTooltip = ({ active, payload, label }) => {        
        if (active && payload && payload.length) {
            const n = payload[0].payload.n
            return (
                <div className="custom-tooltip">
                    {payload.map((line)=> (
                        <p className="label" style={{ color: line.color }}>{`Messages: ${((line.value / 100) * line?.payload?.message_count).toFixed(0)}`}</p>
                    ))}
                </div>
            );
        }

        return null;
    };

    async function getOverallTopicsInformation(event_id) {
        try {
            const result = await axios.get(
                `${window.API_URL}/api/topics/total_msg/${event_id}`
            );
            setOverallTopics(result.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const changeHandler = (e) => {
        let topicMapArr
        if (filteredTopicsData) {
            if (filteredTopicsData.length > 0) {
                topicMapArr = filteredTopicsData
            }
            else {
                topicMapArr = []
            }
        }
        else {
            topicMapArr = []
        }
        if (checkedOptions.length > 0) {
            if (!checkedOptions.includes(e.target.value)) {
                setCheckedOptions(checkedOptions => [...checkedOptions, e.target.value])
                topicMapArr.push(getTopicMap(e.target.value))
            }
            else {
                setCheckedOptions(checkedOptions => checkedOptions.filter((option) => { return option !== e.target.value }))
                // topicMapArr.filter((arr => arr.map((obj)=> {return obj.topic})) !== e.target.value)
                let topic_idx = null
                for (let arr of topicMapArr) {
                    if (arr.array[0].topic === e.target.value) {
                        topic_idx = topicMapArr.indexOf(arr)
                    }

                }
                topicMapArr.splice(topic_idx, 1)
            }
        }
        else {
            setCheckedOptions([e.target.value])
            topicMapArr.push(getTopicMap(e.target.value))
        }
        setFilteredTopicsData(topicMapArr)
        // filterHandler()
    }

    async function getTopicsInformation(event_id) {
        try {
            const result = await axios.get(
                `${window.API_URL}/api/topics/${event_id}`
            );
            console.log(result.data)
            setTopicsInformation(result.data)
            // setTopics(result.data.channels)
        }
        catch (err) {
            console.log(err)
        }
    }


    function getTopicMap(value) {
        if (topicsInformation && overallTopics) {
            let topicMap = new Map();
            for (let index in topicsInformation.messages) {
                for (let msg of topicsInformation.messages[index]) {
                    for (let topic of topicsInformation.topics) {
                        topicMap = createTopicMap(index, msg, topicMap, topic.value)
                    }
                }
            }
            return topicMap.get(value)
        }
    }

    function getRandomColor(idx) {
        if (idx === 0) {
            return "#D30000";
        }
        if (idx === 1) {
            return "#1F6EB8";
        }
        if (idx === 2) {
            return "#C14991";
        }
        if (idx === 3) {
            return "#FBD655";
        }
        if (idx === 4) {
            return "#1FB836";
        }
    }

    useEffect(() => {
        let new_arr = []
        for (let index in topicsInformation?.messages) {
            for (let message of topicsInformation.messages[index]) {
                new_arr.push(message)
            }
        }

        setAllMessagesTogether(new_arr)
    }, [topicsInformation, filteredTopicsData])

    useEffect(() => {
        getEventInformation()
    }, [])


    function zoomDataHandler(data) {
        let left_value = data.leftXValue
        let right_value = data.rightXValue
        if (left_value === null || right_value === null || left_value === right_value) {
            return data.array
        }
        else {
            if (left_value > right_value) {
                let result = data.array.filter((elem) => {
                    let splitedDate = elem.time.split("h")
                    let date = new Date(null, null, null, splitedDate[0], splitedDate[1])
                    return date >= right_value && date <= left_value
                })
                if (result.length > 2) {
                    return result
                }
                else {
                    return data.array
                }
            }
            if (left_value < right_value) {
                let result = data.array.filter((elem) => {
                    let splitedDate = elem.time.split("h")
                    let date = new Date(null, null, null, splitedDate[0], splitedDate[1])
                    return date <= right_value && date >= left_value
                })
                if (result.length > 2) {
                    return result
                }
                else {
                    return data.array
                }
            }
        }
    }

    function zoomOut(index) {
        let topics_data_copy = filteredTopicsData.slice()
        topics_data_copy[index].leftXValue = null
        topics_data_copy[index].rightXValue = null
        setFilteredTopicsData(topics_data_copy)
    }

    function setLeftValue(value, index) {
        setRefAreaIndex(index)
        const obj = {
            value: value,
            index: index
        }
        setLeftXValue(obj)
        setRefAreaLeft(value);
    }

    function setRightValue(value, index) {
        const obj = {
            value: value,
            index: index
        }
        setRightXValue(obj)
        setRefAreaRight(null)
        setRefAreaLeft(null)
    }


    useEffect(() => {
        if (leftXValue !== null && rightXValue !== null) {
            const index = leftXValue.index
            const right = rightXValue.value
            const left = leftXValue.value
            if (leftXValue.value === undefined || rightXValue.value === undefined) {
                zoomOut(index)
                setLeftXValue(null)
                setRightXValue(null)
                return
            }
            if (leftXValue.value === rightXValue.value) {
                setLeftXValue(null)
                setRightXValue(null)
                return
            }
            let topics_data_copy = filteredTopicsData.slice()
            let leftSplitedDate = left.split("h")
            let rightSplitedDate = right.split("h")
            let leftDate = new Date(null, null, null, leftSplitedDate[0], leftSplitedDate[1])
            let rightDate = new Date(null, null, null, rightSplitedDate[0], rightSplitedDate[1])
            topics_data_copy[index].rightXValue = rightDate
            topics_data_copy[index].leftXValue = leftDate
            setFilteredTopicsData(topics_data_copy)
            setLeftXValue(null)
            setRightXValue(null)
        }
    }, [leftXValue, rightXValue])


    return (
        <div className="topics-container">
            <div className="top-row">
                <div className="topics-data">
                    <div className="header">
                        <p>OVERALL BALANCE OF TOPICS</p>
                    </div>
                    {topicsInformation ?
                        <div className="content">
                            <ResponsiveContainer debounce={1000} width="100%" height="100%">
                                <BarChart

                                    data={overallTopics}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="value" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div> : <></>}
                </div>
                <div className="filters">
                    <div className="header">
                        <p>FILTERS</p>
                    </div>
                    <div className="content">
                        <div className="top-filters">
                            {topicsInformation?.topics.map((topic, index) => (
                                <div className="checkboxes" key={index}>
                                    <div>
                                        <input type="checkbox" id={`channel-${index}`} onChange={changeHandler} value={topic.value}></input>
                                        <label for={`channel-${index}`}>{topic.name}</label>
                                    </div>
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>
            {checkedOptions.length > 0 ? (
                <div className="bottom-row">
                    <div className="topics-relevance-container">
                        <div className="header">
                            <p>TOPICS'S RELEVANCE</p>
                        </div>
                        <div className="content">
                            {filteredTopicsData?.map((element, index) => (
                                <>
                                    <div className="header-container">
                                        <h3 className="chart-title">{element.array[index]?.topic}</h3>
                                        <button id="zoom-out" onClick={() => zoomOut(index)}>Zoom Out</button>
                                    </div>
                                    <div className="chart" key={index}>
                                        <ResponsiveContainer debounce={1000} width="100%" height="100%">
                                            <LineChart
                                                width={500}
                                                height={300}
                                                stackOffset="expand"
                                                data={zoomDataHandler(element)}
                                                onMouseDown={(e) => e !== null ? setLeftValue(e.activeLabel, index) : ""}
                                                onMouseMove={(e) => setRefAreaRight(e.activeLabel)}
                                                onMouseUp={(e) => e !== null ? setRightValue(e.activeLabel, index) : ""}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="time" />
                                                <YAxis type="number" domain={[0, 100]}  tickFormatter={(value) => (`${value}%`)} />
                                                <Legend />
                                                <Tooltip content={<CustomTooltip />} />
                                                {topicsInformation.channels.map((key, idx) => {
                                                    return (
                                                        <Line type="monotone" dataKey={key} stroke={getRandomColor(idx)} dot={null} activeDot={{ r: 8 }} />)
                                                })}
                                                {refAreaLeft && refAreaRight && refAreaIndex === index ? (
                                                    <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                                                ) : null}
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div></>
                            ))}
                        </div>
                    </div>
                </div>) : <></>}
        </div>
    );
}

export default Topics