import React from 'react';
import { Typography } from '@mui/material';
import { getRandomColor } from '../../../utils/colors';
import { parseContent } from '../emojiparser/EmojiParser';

function ChatMessage(props) {
    return (
        <div className="chat-message">
            <Typography className="chat-username" component="span" style={{ color: getRandomColor(props.username), fontWeight: "bold" }}>
                {props.username}
            </Typography>

            <Typography className="chat-content" style= {{ fontWeight: "bold", marginLeft: 10}} component="span">
                {parseContent(props.content)}
            </Typography>

        </div>
    );
}

export default ChatMessage;