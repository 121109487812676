import './eventOverview.css';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ActionModal from '../../components/ActionModal/ActionModal';
import InfoCard from '../../components/InfoCard/InfoCard';
import ActionCard from '../../components/ActionCard/ActionCard';
import StatusCard from '../../components/StatusCard/StatusCard';

const drawerWidth = 240;

const Information = () => {
  const [Modalopen, setModalOpen] = useState(false);
  const [btnNumber, setBtnNumber] = useState(0);
  const [startEventState, setStartEventState] = useState(false);
  const [startMatchState, setStartMatchState] = useState(false);
  const [startIntervalState, setStartIntervalState] = useState(false);
  const [startAnalysisState, setStartAnalysisState] = useState(false);
  const [time, setTime] = useState({ ms: 0, s: 0, m: 0, h: 0 });
  const [interv, setInterv] = useState();
  const [intervalNumber, setIntervalNumber] = useState(0);
  const [channels, setChannels] = useState([]);
  let [eventInfo, setEventInfo] = useState('');
  let [eventManipulation, setEventManipulation] = useState('');

  useEffect(() => {
    getEventInformation();
  }, []);

  useEffect(() => {
    if (eventInfo) {
      setEventManipulation(eventInfo.eventInfo);
    }
  }, [eventInfo]);

  useEffect(() => {
    if (eventInfo !== '' && eventInfo.ongoingEvent) {
      console.log(eventInfo.eventInfo.reddittagid);
      // activeAnalysis()
      channelsFormatter();
    }
  }, [eventInfo]);

  function channelsFormatter() {
    let redditChannels = eventInfo.eventInfo.reddittagid.split(',');
    let twitterChannels = eventInfo.eventInfo.twittertagid.split(',');
    let allChannelsTogether = redditChannels;

    for (let channel of twitterChannels) {
      allChannelsTogether.push(channel);
    }

    for (let channel of allChannelsTogether) {
      if (channel === '') {
        allChannelsTogether.splice(allChannelsTogether.indexOf(channel), 1);
      }
    }
    console.log(allChannelsTogether);
    setChannels(allChannelsTogether);
  }

  const getEventInformation = async () => {
    try {
      const result = await axios.get(`${window.API_URL}/api/events/next`);
      let isEventStarted = result.data.eventInfo.iseventstarted;
      let isMatchStarted = result.data.eventInfo.ismatchstarted;
      let isIntervalStarted = result.data.eventInfo.isintervalstarted;
      let isAnalysisStarted = result.data.eventInfo.isanalysisstarted;

      setStartMatchState(isMatchStarted);
      setStartIntervalState(isIntervalStarted);
      setStartAnalysisState(isAnalysisStarted);
      setStartEventState(isEventStarted);

      setEventInfo(result.data);
      console.log(result.data);
    } catch (err) {
      console.log(err);
    }
  };

  const postStartAnalysis = async () => {
    try {
      const result = await axios.post(`${window.API_URL}/api/admin/start_analysis`, {
        event_id: eventInfo.eventInfo.id,
        channels: channels,
        analysis: ['TA', 'SA'],
        playoff_fetch_interval: 2000,
      });
      console.log(eventInfo.eventInfo.id);

      const newObject = { ...eventManipulation, isanalysisstarted: true };
      setEventManipulation(newObject);

      updateEvent(newObject, 'Start', 'Analysis');

      setStartAnalysisState(true);
    } catch (err) {
      console.log(err);
    }
  };

  async function updateEvent(newEvent, type, name) {
    try {
      const result = await axios.put(`${window.API_URL}/api/admin/events/${newEvent.id}`, newEvent);

      toast.success(`${name} ${type}ed with success!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(`Error trying to ${type} ${name}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(err);
    }
  }

  const postEndAnalysis = async () => {
    try {
      const result = await axios.post(`${window.API_URL}/api/admin/end_analysis`, {
        event_id: eventInfo.eventInfo.id,
        channels: channels,
        analysis: ['TA', 'SA'],
        playoff_fetch_interval: 2000,
      });

      const newObject = { ...eventManipulation, isanalysisstarted: false };
      setEventManipulation(newObject);

      updateEvent(newObject, 'End', 'Analysis');

      setStartAnalysisState(false);
    } catch (err) {
      if (err.response.status === 400) {
        toast.error(
          'Error! Please check if all channels have a correct format. Use chat, youtube hashtag (#ABC) or reddit channels (www.reddit.com/....) ',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      console.log(err);
    }
  };
  
  //Time control functions
  let updatedMs = time.ms,
    updatedS = time.s,
    updatedM = time.m,
    updatedH = time.h;

  const run = () => {
    if (updatedM === 60) {
      updatedH++;
      updatedM = 0;
    }
    if (updatedS === 60) {
      updatedM++;
      updatedS = 0;
    }
    if (updatedMs === 100) {
      updatedS++;
      updatedMs = 0;
    }
    updatedMs++;
    return setTime({ ms: updatedMs, s: updatedS, m: updatedM, h: updatedH });
  };

  useEffect(() => {
    if (eventManipulation) {
      console.log(eventManipulation);
    }
  }, [eventManipulation]);

  const eventStart = async (event) => {
    event.preventDefault();
    const newObject = { ...eventManipulation, iseventstarted: true };
    await setEventManipulation(newObject);

    updateEvent(newObject, 'Start', 'Event');

    setStartEventState(true);
    setStartMatchState(false);
    setStartAnalysisState(false);
    run();
    setInterv(setInterval(run, 10));
    console.log('Início do evento!');
    setModalOpen(false);
    handleModalClose();
  };

  const eventEnd = () => {
    const endTime = time.h + 'h ' + time.m + 'm ' + time.s + 's ' + time.ms + 'ms';

    const newObject = {
      ...eventManipulation,
      iseventstarted: false,
      isintervalstarted: false,
      isanalysisstarted: false,
      ismatchstarted: false,
    };
    setEventManipulation(newObject);

    updateEvent(newObject, 'End', 'Event');

    setStartEventState(false);
    setStartMatchState(false);
    setStartAnalysisState(false);
    setStartIntervalState(false);
    clearInterval(interv);
    setTime({ ms: 0, s: 0, m: 0, h: 0 });
    console.log('Tempo do evento: ' + endTime);
    handleModalClose();
  };

  const matchStart = () => {
    const startTime = time.h + 'h ' + time.m + 'm ' + time.s + 's ' + time.ms + 'ms';

    const newObject = { ...eventManipulation, ismatchstarted: true };
    setEventManipulation(newObject);

    updateEvent(newObject, 'Start', 'Match');

    setStartMatchState(true);
    console.log('Início do Jogo: ' + startTime);
    handleModalClose();
  };

  const matchEnd = () => {
    const endTime = time.h + 'h ' + time.m + 'm ' + time.s + 's ' + time.ms + 'ms';

    const newObject = { ...eventManipulation, ismatchstarted: false, isintervalstarted: false };
    setEventManipulation(newObject);

    updateEvent(newObject, 'End', 'Match');

    setStartMatchState(false);
    setStartIntervalState(false);
    console.log('Fim do Jogo: ' + endTime);
    setModalOpen(false);
    handleModalClose();
  };
  const analysisStart = () => {
    postStartAnalysis();
    setModalOpen(false);
    handleModalClose();
  };

  const analysisEnd = () => {
    postEndAnalysis();
    setModalOpen(false);
    handleModalClose();
  };

  const intervalStart = () => {
    setIntervalNumber(intervalNumber + 1);
    const intervalTime = time.h + 'h ' + time.m + 'm ' + time.s + 's ' + time.ms + 'ms ';

    const newObject = { ...eventManipulation, isintervalstarted: true };
    setEventManipulation(newObject);

    updateEvent(newObject, 'Start', 'Interval');

    console.log(`Intervalo ${intervalNumber + 1}: ${intervalTime}`);
    setStartIntervalState(true);
    handleModalClose();
  };

  const intervalEnd = () => {
    const intervalEndTime = time.h + 'h ' + time.m + 'm ' + time.s + 's ' + time.ms + 'ms ';

    const newObject = { ...eventManipulation, isintervalstarted: false };
    setEventManipulation(newObject);

    updateEvent(newObject, 'End', 'Interval');

    console.log(`Fim do intervalo ${intervalNumber}: ${intervalEndTime}`);
    setStartIntervalState(false);
    handleModalClose();
  };

  const handleModalClose = () => setModalOpen(false);

  const handleFirstModalOpen = () => {
    setBtnNumber(1);
    setModalOpen(true);
  };

  const handleSecondModalOpen = () => {
    setBtnNumber(2);
    setModalOpen(true);
  };

  const handleThirdModalOpen = () => {
    setBtnNumber(3);
    setModalOpen(true);
  };

  const handleFourthModalOpen = () => {
    setBtnNumber(4);
    setModalOpen(true);
  };

  const handleFifthModalOpen = () => {
    setBtnNumber(5);
    setModalOpen(true);
  };

  const handleSixthModalOpen = () => {
    setBtnNumber(6);
    setModalOpen(true);
  };

  const handleSeventhModalOpen = () => {
    setBtnNumber(7);
    setModalOpen(true);
  };

  const handleEighthModalOpen = () => {
    setBtnNumber(8);
    setModalOpen(true);
  };

  return (
    <div>
      {eventInfo.ongoingEvent ? (
        <Box
          className='mainBox'
          component='main'
          sx={{
            flexGrow: 1,
            p: 0,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Grid id='mainGrid' sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` } }} container>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              {eventInfo.eventInfo ? (
                <InfoCard
                  props={{
                    eventData: eventInfo,
                  }}
                />
              ) : (
                ''
              )}
              <ActionCard
                props={{
                  handleFirstModalOpen: handleFirstModalOpen,
                  handleSecondModalOpen: handleSecondModalOpen,
                  handleThirdModalOpen: handleThirdModalOpen,
                  handleFourthModalOpen: handleFourthModalOpen,
                  handleFifthModalOpen: handleFifthModalOpen,
                  handleSixthModalOpen: handleSixthModalOpen,
                  handleSeventhModalOpen: handleSeventhModalOpen,
                  handleEighthModalOpen: handleEighthModalOpen,
                  startEventState: startEventState,
                  startMatchState: startMatchState,
                  startIntervalState: startIntervalState,
                  startAnalysisState: startAnalysisState,
                }}
              />
            </Grid>
            {btnNumber === 1 ? (
              <ActionModal
                props={{
                  label: 'Are you sure you want to start the event?',
                  isOpen: Modalopen,
                  handleModalClose: handleModalClose,
                  handleEvent: eventStart,
                }}
              />
            ) : btnNumber === 2 ? (
              <ActionModal
                props={{
                  label: 'Are you sure you want to end the event?',
                  isOpen: Modalopen,
                  handleModalClose: handleModalClose,
                  handleEvent: eventEnd,
                }}
              />
            ) : btnNumber === 3 ? (
              <ActionModal
                props={{
                  label: 'Are you sure you want to start the match?',
                  isOpen: Modalopen,
                  handleModalClose: handleModalClose,
                  handleEvent: matchStart,
                }}
              />
            ) : btnNumber === 4 ? (
              <ActionModal
                props={{
                  label: 'Are you sure you want to end the match?',
                  isOpen: Modalopen,
                  handleModalClose: handleModalClose,
                  handleEvent: matchEnd,
                }}
              />
            ) : btnNumber === 5 ? (
              <ActionModal
                props={{
                  label: 'Are you sure you want to start the interval?',
                  isOpen: Modalopen,
                  handleModalClose: handleModalClose,
                  handleEvent: intervalStart,
                }}
              />
            ) : btnNumber === 6 ? (
              <ActionModal
                props={{
                  label: 'Are you sure you want to end the interval?',
                  isOpen: Modalopen,
                  handleModalClose: handleModalClose,
                  handleEvent: intervalEnd,
                }}
              />
            ) : btnNumber === 7 ? (
              <ActionModal
                props={{
                  label: 'Are you sure you want to start sentiments analysis?',
                  isOpen: Modalopen,
                  handleModalClose: handleModalClose,
                  handleEvent: analysisStart,
                }}
              />
            ) : btnNumber === 8 ? (
              <ActionModal
                props={{
                  label: 'Are you sure you want to end sentiments analysis?',
                  isOpen: Modalopen,
                  handleModalClose: handleModalClose,
                  handleEvent: analysisEnd,
                }}
              />
            ) : (
              <></>
            )}
            <Grid item lg={6} md={12} sm={12} xs={12} style={{ paddingLeft: '30px' }}>
              <StatusCard
                props={{
                  iseventstarted: eventManipulation.iseventstarted,
                  ismatchstarted: eventManipulation.ismatchstarted,
                  isintervalstarted: eventManipulation.isintervalstarted,
                  isanalysisstarted: eventManipulation.isanalysisstarted,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <div className='blank_page'>
          <h2>There are no ongoing events at the moment...</h2>
        </div>
      )}
    </div>
  );
};

export default Information;
